import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../layout.pt";
import SEO from "../seo";
import Sticky from "react-sticky-el";

const CaseStudyPagePT = ({ data }) => {
    const caseStudy = data.wbapi.Cottonhat_casestudy;

    return (
        <Layout>
            <SEO title={`${caseStudy.title.pt_PT ? caseStudy.title.pt_PT : caseStudy.title}`} description={`${caseStudy.metaDescription && caseStudy.metaDescription.pt_PT ? caseStudy.metaDescription.pt_PT : ""}`} ogImage={`${caseStudy.gallery[0].url}?w=1170&h=600&fit=crop`} lang="pt" />

            <div className="brook-portfolio-details bg_color--1 pt--100">
                <div className="container">
                    <div className="row pt--100 pt_md--5 pt_sm--5 pb--80">
                        <div className="col-lg-4 col-12 order-2 order-lg-1 mt_md--40 mt_sm--40 Cccc" id="is_stuck_inner">
                            <Sticky boundaryElement={".brook-portfolio-details"}>
                                <div className="portfolio-left bk-portfolio-details">
                                    <div className="portfolio-main-info pt--150">
                                        <h2 className="heading heading-h2 line-height-1-42">{caseStudy.title.pt_PT ? caseStudy.title.pt_PT : caseStudy.title}</h2>
                                        <div className="portfolio-content mt--75 mb--75 mt_md--40 mb_md--40 mt_sm--40 mb_sm--40">
                                            <h5 className="heading heading-h5">SOBRE O PROJETO</h5>
                                            <div className="desc mt--20">
                                                <div className="bk_pra" dangerouslySetInnerHTML={{ __html: caseStudy.about.pt_PT ? caseStudy.about.pt_PT : caseStudy.about }} />
                                            </div>
                                        </div>
                                        <div className="portfolio-details-list">
                                            <div className="details-list">
                                                <label htmlFor="date">Data</label>
                                                <span>{caseStudy.date}</span>
                                            </div>

                                            <div className="details-list">
                                                <label htmlFor="client">Cliente</label>
                                                <span>{caseStudy.client}</span>
                                            </div>

                                            <div className="details-list">
                                                <label htmlFor="categories">Categorias</label>
                                                {caseStudy.category.length > 0 && caseStudy.category.map(cat => <div dangerouslySetInnerHTML={{ __html: cat.labelPT ? cat.labelPT : cat.label }}></div>)}
                                            </div>

                                            <div className="details-list">
                                                <label htmlFor="website">Website URL</label>
                                                <span>
                                                    <Link target="_blank" href={`${caseStudy.website}`}>
                                                        {caseStudy.website}
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Sticky>
                        </div>
                        <div className="col-lg-8 col-12 order-1 order-lg-2">
                            <div className="portfolio-right portfolio-details-gallery text-center text-lg-right">
                                {caseStudy.gallery.map(img => {
                                    return (
                                        <div className="portfolio-image">
                                            <img src={img.url} alt={caseStudy.title.pt_PT ? caseStudy.title.pt_PT : caseStudy.title} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container bg_color--1">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-nav-list pt--50 pb--150 pb_md--80 pb_sm--60 pt_md--5 pt_sm--5">
                                <div className="portfolio-page prev">
                                    <div className="inner">
                                        <Link to="/">
                                            <p>Prev</p>
                                            <h3 className="heading heading-h3">MISSING RELATED</h3>
                                        </Link>
                                    </div>
                                </div>
                                <div className="portfolio-page next mt_sm--30">
                                    <div className="inner">
                                        <Link to="/">
                                            <p>Next</p>
                                            <h3 className="heading heading-h3">MISSING RELATED</h3>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </Layout>
    );
};

export default CaseStudyPagePT;

export const query1 = graphql`
    query($_id: ID!) {
        wbapi {
            Cottonhat_casestudy(project: "592d4cf5b549b452a450d829", id: $_id) {
                project_id
                title {
                    en_EN
                    pt_PT
                }
                subtitle {
                    en_EN
                    pt_PT
                }
                website
                about {
                    en_EN
                    pt_PT
                }
                date
                _id
                uri
                normalized_name
                overlayColor
                client
                category {
                    code
                    label
                }
                gallery {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                        }
                    }
                }
                creat_default {
                    createdAt
                }
                photo {
                    url
                    edition_options {
                        crop_options {
                            height
                            width
                            x
                            y
                        }
                    }
                }
            }
        }
    }
`;
